import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import Start from 'components/start/start';
import Sport from 'components/sport/sport';
import Live from 'components/live/live.js';
import Profile from 'components/profile/profile';
import MyBets from 'components/mybets/mybets';
import Register from 'components/login/register';
import Login from 'components/login/login';
import Calendar from 'components/profile/calendar';
import BettingHistory from 'components/profile/bettingHistory/BettingHistory.js';
import BettingHistoryDetail from 'components/general/betting_history_detail';
import Bank from 'components/profile/bank';
import Transactions from 'components/profile/transactions.js';
import ConsentHistory from 'components/profile/consenthistory.js';
import Payout from 'components/profile/payout.js';
import SportLeagueDetail from 'components/sport/SportLeagueDetail';
import ClearFavorites from 'components/start/clear_favorites';
import Results from 'components/start/results';
import ResultDetail from 'components/start/result_detail';
import MatchDetail from 'components/general/match_detail';
import Contact from 'components/profile/contact';
import ProfileSettings from 'components/profile/profile_settings';
import ChangePassword from 'components/profile/change_password';
import EditProfile from 'components/profile/edit_profile';
import UploadDocument from 'components/profile/upload_document';
import ResponsibleGaming from 'components/profile/responsible_gaming';
import BetSlipList from 'components/betslip/bet_slip';
import Search from 'components/search/search';
import Slot from 'components/slot/slot';
import SlotGame from 'components/slot/slot_game';
import LiveGame from 'components/slot/live_game';
import CasinoBase from 'components/slot/CasinoBase';
import Casino from 'components/slot/casino';
import SportDetailsMatches from 'components/sport/SportDetailsMatches';
import QRDeposit from 'components/profile/qrDeposit';

function RequireAuth({ children }) {
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

const routes = [
  {
    path: '/search',
    element: (
      <RequireAuth>
        <Search />
      </RequireAuth>
    )
  },
  {
    path: '/qr-deposit',
    title: 'QR Deposit',
    element: (
      <RequireAuth>
        <QRDeposit />
      </RequireAuth>
    )
  },
  {
    path: '/sport',
    title: 'Sport Page',
    element: (
      <RequireAuth>
        <Sport />
      </RequireAuth>
    )
  },
  {
    path: '/sport-details/:sportType?',
    element: (
      <RequireAuth>
        <SportLeagueDetail />
      </RequireAuth>
    )
  },
  {
    path: '/sport-details/:sportType/matches',
    element: (
      <RequireAuth>
        <SportDetailsMatches />
      </RequireAuth>
    )
  },
  {
    path: '/live',
    title: 'Live Page',
    element: (
      <RequireAuth>
        <Live />
      </RequireAuth>
    )
  },
  {
    path: '/login',
    title: 'Login Page',
    element: <Login />
  },
  {
    path: '/register',
    title: 'Register Page',
    element: <Register />
  },
  {
    path: '/mybets',
    element: (
      <RequireAuth>
        <MyBets />
      </RequireAuth>
    )
  },
  {
    path: '/slot',
    title: 'Slot',
    element: (
      <RequireAuth>
        <Slot />
      </RequireAuth>
    )
  },
  {
    path: '/casino',
    title: 'Casino',
    element: (
      <RequireAuth>
        <Casino />
      </RequireAuth>
    )
  },
  {
    path: '/casinobase',
    title: 'Casino',
    element: (
      <RequireAuth>
        <CasinoBase />
      </RequireAuth>
    )
  },
  {
    path: '/slot-game',
    title: 'Slot Game',
    element: (
      <RequireAuth>
        <SlotGame />
      </RequireAuth>
    )
  },
  {
    path: '/live-game',
    title: 'Live Game',
    element: (
      <RequireAuth>
        <LiveGame />
      </RequireAuth>
    )
  },
  {
    path: '/profile',
    title: 'My Profile',
    element: (
      <RequireAuth>
        <Profile />
      </RequireAuth>
    )
  },
  {
    path: '/calendar',
    element: (
      <RequireAuth>
        <Calendar />
      </RequireAuth>
    )
  },
  {
    path: '/bettinghistory',
    element: (
      <RequireAuth>
        <BettingHistory />
      </RequireAuth>
    )
  },
  {
    path: '/bettinghistorydetail/:slipId?',
    element: (
      <RequireAuth>
        <BettingHistoryDetail />
      </RequireAuth>
    )
  },
  {
    path: '/bank',
    element: (
      <RequireAuth>
        <Bank />
      </RequireAuth>
    )
  },
  {
    path: '/transactions/:customerId',
    element: (
      <RequireAuth>
        <Transactions />
      </RequireAuth>
    )
  },
  {
    path: '/payout',
    element: (
      <RequireAuth>
        <Payout />
      </RequireAuth>
    )
  },
  {
    path: '/consenthistory',
    element: (
      <RequireAuth>
        <ConsentHistory />
      </RequireAuth>
    )
  },
  {
    path: '/clear-favorites',
    element: (
      <RequireAuth>
        <ClearFavorites />
      </RequireAuth>
    )
  },
  // {
  //   path: '/automatic-bets',
  //   element: <AutomaticBets />
  // },
  {
    path: '/results/:sportTypeMenuVisible?',
    element: (
      <RequireAuth>
        <Results />
      </RequireAuth>
    )
  },
  {
    path: '/results/:sportType/:itemName',
    element: (
      <RequireAuth>
        <ResultDetail />
      </RequireAuth>
    )
  },
  {
    path: '/match-detail',
    element: (
      <RequireAuth>
        <MatchDetail />
      </RequireAuth>
    )
  },
  {
    path: '/contact',
    element: (
      <RequireAuth>
        <Contact />
      </RequireAuth>
    )
  },
  {
    path: '/profilesettings',
    element: (
      <RequireAuth>
        <ProfileSettings />
      </RequireAuth>
    )
  },
  {
    path: '/changepassword',
    element: (
      <RequireAuth>
        <ChangePassword />
      </RequireAuth>
    )
  },
  {
    path: '/editprofile',
    element: (
      <RequireAuth>
        <EditProfile />
      </RequireAuth>
    )
  },
  {
    path: '/uploaddocument',
    element: (
      <RequireAuth>
        <UploadDocument />
      </RequireAuth>
    )
  },
  {
    path: '/responsiblegaming',
    element: (
      <RequireAuth>
        <ResponsibleGaming />
      </RequireAuth>
    )
  },
  {
    path: '/bet-slip',
    element: (
      <RequireAuth>
        <BetSlipList />
      </RequireAuth>
    )
  },
  {
    path: '/',
    title: 'Start Page',
    element: (
      <RequireAuth>
        <Start />
      </RequireAuth>
    )
  },
  {
    path: '*',
    element: <div>404</div>
  }
];

export default routes;
