import OddButton from '../match/odd_button';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { AWS_BUCKET_URL } from '../../toolkit/constants';
import { useLocation } from 'react-router-dom';
import { OddsValueTypeTextCreator } from 'toolkit/utils';
import { EnumMarketName } from 'toolkit/Enums';

function BetBox(props) {
  const { providerId, marketStringId, outcomeId } = props;
  const producerStatus = useSelector((state) => state.match.producerStatus);
  const match = useSelector((state) => state.match.events[providerId]);
  const [betTitle, setBetTitle] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [path, setPath] = useState('');
  const location = useLocation();
  const nonTitledMarkets = [
    EnumMarketName.ANYTIMEGOALSCORER_40,
    EnumMarketName.NEXTGOALSCORER_38,
    EnumMarketName.LASTGOALSCORER_39
  ];

  useEffect(() => {
    try {
      let market = match.markets?.find((m) => m.stringId === props.marketStringId);
      let outcome = market?.outcomes.find((o) => o.id === props.outcomeId);
      let title = OddsValueTypeTextCreator(
        match.sport.toString(),
        market?.id.toString(),
        outcome?.id,
        outcome?.name
      );

      setDisabled(
        market?.marketStatus !== 6 ||
          (market?.producer === 1 && producerStatus.preMatch === false) ||
          (market?.producer === 2 && producerStatus.live === false)
      );
      if (!nonTitledMarkets.includes(market?.id)) setBetTitle(title);
    } catch (error) {
      console.log(error);
    }
  }, [match, producerStatus.live, producerStatus.preMatch]);

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  if (disabled)
    return (
      <div className="d-flex flex-column text-center text-dark fs-8">
        {location.pathname === '/match-detail' && <span>{betTitle}</span>}
        <div className="justify-content-center w-100 px-1 text-center text-muted betOddsBox light-black-svg">
          <img width="20" height="16" src={`${AWS_BUCKET_URL}/Icons/lockicon.svg`} />
        </div>
      </div>
    );

  return (
    <>
      {props.showOddTitle && location.pathname === '/match-detail' && (
        <div className="d-flex flex-column text-center text-dark fs-8">
          <span>{betTitle}</span>
        </div>
      )}
      <OddButton
        providerId={props.providerId}
        outcomeId={props.outcomeId}
        marketStringId={props.marketStringId}
      />
    </>
  );
}

BetBox.defaultProps = {
  showOddTitle: true
};

export default BetBox;
