/* eslint-disable no-case-declarations */
import BetBox from './bet_box';
import { Row, Col, Container, Stack } from 'react-bootstrap';
import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import * as _ from 'lodash';
import {
  getFootballOddTypeText,
  MarketIdToMarketName,
  ReverseName,
  OutcomeName
} from './../../toolkit/utils';
import { EnumMarketName } from '../../toolkit/Enums.js';

function BetTitleAndBox(props) {
  let { market, matchId, isLastMarket } = props;
  const [path, setPath] = useState('');
  const location = useLocation();

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);
  // get route path

  function compareById(a, b) {
    return parseInt(a.id) - parseInt(b.id);
  }

  function getMarkets() {
    const oddsSize = 4;
    let sortedOutcomes;

    if (market.id == EnumMarketName.WINNERBOTHTEAMSTOSCORE_35) {
      const outcomeIds = ['78', '82', '86', '80', '84', '88'];
      sortedOutcomes = outcomeIds
        .map((id) => market.outcomes.find((o) => o.id === id))
        .filter((o) => o !== undefined);
    } else {
      sortedOutcomes = [...(market.outcomes || [])].sort(compareById);
    }

    switch (market.id) {
      case '8':
      case EnumMarketName.NEXTGOAL_8:
        return (
          <Container>
            <Row className="align-items-start">
              {path === '/match-detail' && (
                <Col xs={6} className="bet_name">
                  <div>&nbsp;</div>
                  {OutcomeName(market.id, market.stringId, market.name)}
                </Col>
              )}
              <Col className={`${path === '/match-detail' ? 'ps-3' : 'pl-0'}`} xs={path === '/match-detail' ? 6 : 10}>
                <Row className="bets gy-2 gx-1">
                  {_.orderBy(sortedOutcomes, ['id'], ['asc']).map((outcome, index) => (
                    <Col
                      xs={4}
                      className="betItem m-0"
                      key={market.id + ' ' + (outcome.id || matchId + '' + index)}>
                      <BetBox
                        providerId={matchId}
                        outcomeId={outcome.id}
                        marketStringId={market.stringId}
                        sport={props.sportType}
                        path={props.path}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Container>
        );
      case 66:
      case 187:
      case 223:
      case 226:
      case 227:
      case 256:
      case EnumMarketName.HANDICAP_14:
      case '14':
        return (
          <Container className={`${path === '/match-detail' ? '' : 'bottom-negative-075'}`}>
            <Row className={`align-items-start`}>
              {path === '/match-detail' ? (
                <Col xs={6} className="bet_name">
                  <div className="text-start">
                    <div>&nbsp;</div>
                    {OutcomeName(market.id, market.stringId, market.name)}
                  </div>
                </Col>
              ) : (
                <Col xs={2} className="bet_name yellow-color">
                  {market.renderNull !== true && (
                    <div style={{ paddingTop: 5 }}>
                      <div className="yellow-color" style={{ marginBottom: 5.8 }}>
                        {OutcomeName(market.id, market.stringId, market.name).split(':')[0]}
                      </div>
                      <div className="yellow-color">
                        {OutcomeName(market.id, market.stringId, market.name).split(':')[1]}
                      </div>
                    </div>
                  )}
                </Col>
              )}
              {/* <Col
                xs={path === '/match-detail' ? 6 : 2}
                className={`bet_name ${path === '/match-detail' ? '' : 'yellow-color'}`}>
                {path === '/match-detail' ? (
                  <div style={{ textAlign: 'center' }}>
                    <div>&nbsp;</div>
                    {OutcomeName(market.id, market.stringId, market.name)}
                  </div>
                ) : (
                  <>
                    {market.renderNull !== true && (
                      <div style={{ paddingTop: 5 }}>
                        <div className="yellow-color" style={{ marginBottom: 5.8 }}>
                          {OutcomeName(market.id, market.stringId, market.name).split(':')[0]}
                        </div>
                        <div className="yellow-color">
                          {OutcomeName(market.id, market.stringId, market.name).split(':')[1]}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </Col> */}
              {/* <Col style={{ textAlign: 'center' }}>
                  {OutcomeName(market.id, market.stringId, market.name)}
                </Col> */}
              <Col className={`${path === '/match-detail' ? 'ps-3' : 'pl-0'}`} xs={path === '/match-detail' ? 6 : 10}>
                <Row className="bets gy-2 gx-1 justify-content-end">
                  {sortedOutcomes.map((outcome, index) => (
                    <Col
                      key={market.id + ' ' + (outcome.id || matchId + '' + index)}
                      xs={4}
                      className={`betItem m-0 ${
                        isLastMarket === true || path === '/match-detail' ? '' : 'pb-15'
                      }`}>
                      <BetBox
                        providerId={matchId}
                        outcomeId={outcome.id}
                        marketStringId={market.stringId}
                        sport={props.sportType}
                        path={props.path}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Container>
        );
      case EnumMarketName.TOTAL_18:
      case '116':
      case 225:
      case 237:
      case 238:
      case '18':
      case '90':
        return (
          <Container>
            <Row className="align-items-start">
              {path === '/match-detail' && (
                <Col xs={6} className="bet_name text-start">
                  <div>&nbsp;</div>
                  {/* {market.id === '116' && 'OT-'} */}
                  {OutcomeName(market.id, market.stringId, market.name)}
                </Col>
              )}
              <Col className={`${path === '/match-detail' ? 'ps-3' : 'pl-0'}`} xs={path === '/match-detail' ? 6 : 10}>
                <Row className="bets gy-2 gx-1 justify-content-end">
                  {path !== '/match-detail' && (
                    <Col
                      className={`bet_name pt-10 ${path === '/match-detail' ? '' : 'yellow-color'}`}
                      xs={4}>
                      {market.name === ''
                        ? '\u00A0'
                        : OutcomeName(market.id, market.stringId, market.name)}
                    </Col>
                  )}
                  {sortedOutcomes.map((outcome, index) => (
                    <Col
                      key={market.id + ' ' + (outcome.id || matchId + '' + index)}
                      xs={4}
                      className={`betItem m-0 ${
                        isLastMarket === true || path === '/match-detail' ? '' : 'pb-15'
                      }`}>
                      <BetBox
                        providerId={matchId}
                        outcomeId={outcome.id}
                        marketStringId={market.stringId}
                        sport={props.sportType}
                        path={props.path}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Container>
        );
      case EnumMarketName.HOMETOTAL_19:
      case EnumMarketName.AWAYTOTAL_20:
      case '19':
      case '20':
      case '91':
      case '92':
        return (
          <Container>
            <Row className="align-items-start">
              <Col xs={6} className="bet_name">
                <div className="text-start">
                  <div>&nbsp;</div>
                  {OutcomeName(market.id, market.stringId, market.name)}
                </div>
              </Col>
              <Col xs={6}>
                <Row className="bets gy-2 gx-1 justify-content-end">
                  {sortedOutcomes.map((outcome, index) => (
                    <Col
                      key={market.id + ' ' + (outcome.id || matchId + '' + index)}
                      xs={oddsSize}
                      className="betItem m-0">
                      <BetBox
                        providerId={matchId}
                        outcomeId={outcome.id}
                        marketStringId={market.stringId}
                        sport={props.sportType}
                        path={props.path}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Container>
        );
      case EnumMarketName.NEXTGOALSCORER_38:
      case EnumMarketName.LASTGOALSCORER_39:
      case EnumMarketName.ANYTIMEGOALSCORER_40:
        return (
          <Stack direction="horizontal" gap={1}>
            {sortedOutcomes
              .filter((o) => o.odds !== 0)
              .map((outcome, index) => (
                <Col
                  key={market.id + ' ' + (outcome.id || matchId + '' + index)}
                  xs={2}
                  className="betItem"
                  style={{ float: 'right' }}>
                  <BetBox
                    providerId={matchId}
                    outcomeId={outcome.id}
                    marketStringId={market.stringId}
                    showOddTitle={false}
                    sport={props.sportType}
                    path={props.path}
                  />
                </Col>
              ))}
          </Stack>
        );
      case EnumMarketName.CORRECTSCORE_41:
        const homeScore = [];
        const draw = [];
        const awayScore = [];
        sortedOutcomes.forEach((outcome, index) => {
          // if (outcome.id === '324') return;
          const outcomeName = outcome.name.split(':');
          const targetArray =
            Number(outcomeName[0]) > Number(outcomeName[1])
              ? homeScore
              : outcomeName[0] === outcomeName[1]
                ? draw
                : awayScore;
          targetArray.push(
            <Stack
              key={market.id + ' ' + (outcome.id || matchId + '' + index)}
              // gap={1}
              className="flex-grow-0">
              <BetBox
                providerId={matchId}
                outcomeId={outcome.id}
                marketStringId={market.stringId}
                sport={props.sportType}
                path={props.path}
              />
            </Stack>
          );
        });
        return (
          <>
            <Container>
              <Row className="align-items-start">
                <Col className="bet_name" xs={6}>
                  <div className="mb-1">&nbsp;</div>
                  {MarketIdToMarketName(market.id, market.stringId).split(' + ')[0]}
                </Col>
                <Col xs={6}>
                  <Row className="gx-1">
                    <Col xs={4} className={'d-flex flex-column gap-2'}>
                      {homeScore}
                    </Col>
                    <Col xs={4} className={'d-flex flex-column gap-2'}>
                      {draw}
                    </Col>
                    <Col xs={4} className={'d-flex flex-column gap-2'}>
                      {awayScore}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </>
        );
      case '37':
      case '65':
      case '66':
      case '69':
      case '70':
      case '166':
      case '547':
      case EnumMarketName.WINNERANDOVERUNDER_37:
      case EnumMarketName.FIRSTHALFHANDICAP_65:
      case EnumMarketName.FIRSTHALFHANDICAP_66:
      case EnumMarketName.FIRSTHALFHOMEOVERUNDER_69:
      case EnumMarketName.FIRSTHALFAWAYOVERUNDER_70:
      case EnumMarketName.OVERUNDERCORNERS_166:
      case EnumMarketName.DOUBLECHANCETOTAL_547:
        return (
          <Container>
            <Row className="align-items-start">
              <Col xs={6} className="bet_name">
                <div className="text-start">
                  <div>&nbsp;</div>
                  {OutcomeName(market.id, market.stringId, market.name)}
                </div>
              </Col>
              <Col xs={6}>
                <Row className="bets gy-2 gx-1 justify-content-end">
                  {sortedOutcomes?.map((outcome, index) => (
                    <Col
                      key={market.id + ' ' + (outcome.id || matchId + '' + index)}
                      xs={oddsSize}
                      className="betItem m-0">
                      <BetBox
                        providerId={matchId}
                        outcomeId={outcome.id}
                        marketStringId={market.stringId}
                        sport={props.sportType}
                        path={props.path}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Container>
        );
      case '68':
      case EnumMarketName.FIRSTHALFOVERUNDER_68:
        return (
          <Container>
            <Row className="align-items-start">
              <Col xs={6} className="bet_name">
                <div className="text-start">
                  <div>&nbsp;</div>
                  {OutcomeName(market.id, market.stringId, market.name)}
                </div>
              </Col>
              <Col xs={6}>
                <Row className="bets gy-2 gx-1 justify-content-end">
                  {sortedOutcomes
                    ?.sort((a, b) => a.id - b.id)
                    .map((outcome, index) => (
                      <Col
                        key={market.id + ' ' + (outcome.id || matchId + '' + index)}
                        xs={oddsSize}
                        className="betItem m-0">
                        <BetBox
                          providerId={matchId}
                          outcomeId={outcome.id}
                          marketStringId={market.stringId}
                          sport={props.sportType}
                          path={props.path}
                        />
                      </Col>
                    ))}
                </Row>
              </Col>
            </Row>
          </Container>
        );
      case '818':
      case '819':
      case EnumMarketName.HALFTIMEFULLTIMETOTAL_818:
      case EnumMarketName.HALFTIMEFULLTIMEFIRSTHALFTOTAL_819:
        let groupedMarkets = _.values(
          _.groupBy(sortedOutcomes, (x) => {
            if (x.id >= 1845) return x.id;

            return Number(x.id) + 9;
          })
        );
        return (
          <Container>
            <Row className="bet_name">{MarketIdToMarketName(market.id, market.stringId)}</Row>
            {groupedMarkets.map((outcome, index) => (
              <Row
                style={{ margin: 0 }}
                key={market.id + ' ' + (outcome.id || matchId + '' + index)}>
                <Col xs={6} className="bet_name_center" style={{ marginTop: 20 }}>
                  {
                    getFootballOddTypeText(
                      market.id.toString(),
                      outcome[0].id.toString(),
                      outcome[0].name
                    ).split(' ')[0]
                  }
                </Col>
                <Col xs={6} className="bets">
                  <Row className="bets">
                    <Col xs={6} className="d-flex flex-column">
                      <BetBox
                        providerId={matchId}
                        outcomeId={outcome[0].id}
                        marketStringId={market.stringId}
                      />
                    </Col>
                    {outcome.length > 1 && (
                      <Col xs={6} className="d-flex flex-column">
                        <BetBox
                          providerId={matchId}
                          outcomeId={outcome[1].id}
                          marketStringId={market.stringId}
                        />
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            ))}
          </Container>
        );
      case '820':
      case EnumMarketName.HALFTIMEFULLTIMEEXACTGOALS_820:
        let groupedMarkets2 = _.values(
          _.groupBy(sortedOutcomes, (x) => {
            return x.name.split('&')[0];
          })
        );
        return (
          <Container>
            <Row className="bet_name">
              <div>&nbsp;</div>
              {MarketIdToMarketName(market.id, market.stringId)}
            </Row>
            {groupedMarkets2.map((outcome, index) => (
              <Fragment key={index}>
                <Row>
                  <Col xs={6} className="bet_name_center" style={{ marginTop: '7%' }}>
                    {
                      getFootballOddTypeText(
                        market.id.toString(),
                        outcome[0].id.toString(),
                        outcome[0].name
                      ).split(' ')[0]
                    }
                  </Col>
                  <Col xs={6} className="bets">
                    <Row className="bets gy-2 gx-1 justify-content-end">
                      {outcome.map((outcomes, index1) => (
                        <Col
                          key={market.id + ' ' + (outcome.id || matchId + '' + index1)}
                          xs={4}
                          className="d-flex flex-column">
                          <BetBox
                            providerId={matchId}
                            outcomeId={outcomes.id}
                            marketStringId={market.stringId}
                            sport={props.sportType}
                            path={props.path}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
                {groupedMarkets2.length !== index + 1 ? <hr></hr> : null}
              </Fragment>
            ))}
          </Container>
        );
      case '888':
      case '891':
      case EnumMarketName.ANYTIMEGOALSCORERWINNER_888:
      case EnumMarketName.NEXTGOALSCORERWINNER_891:
        return (
          <Container>
            <Row className="mb-1">
              <Col xs={6} className="bet_name" style={{ paddingTop: 10 }}>
                <div>&nbsp;</div>
                {ReverseName(sortedOutcomes[0].name.split('&')[0])}
              </Col>
              <Col xs={6} className="bets">
                <Row className="bets gy-2 gx-1">
                  {sortedOutcomes
                    .filter((outcome) => outcome.id !== '1897')
                    .map((outcome, index) => (
                      <Col
                        xs={oddsSize}
                        className="betItem"
                        key={market.id + ' ' + (outcome.id || matchId + '' + index)}>
                        <BetBox
                          providerId={matchId}
                          outcomeId={outcome.id}
                          marketStringId={market.stringId}
                          sport={props.sportType}
                          path={props.path}
                        />
                      </Col>
                    ))}
                </Row>
              </Col>
            </Row>
          </Container>
        );
      case EnumMarketName.WINNERBOTHTEAMSTOSCORE_35:
        return (
          <Container>
            <Row className="align-items-start">
              <Col xs={path === '/match-detail' ? 6 : 2} className="bet_name">
                <div>&nbsp;</div>
                {path === '/match-detail' && OutcomeName(market.id, market.stringId, market.name)}
              </Col>
              <Col xs={path === '/match-detail' ? 6 : 10}>
                <Row className="bets gy-2 gx-1" style={{ alignItems: 'flex-end' }}>
                  {sortedOutcomes?.map((outcome, index) => (
                    <Col
                      key={market.id + ' ' + (outcome.id || matchId + '' + index)}
                      xs={oddsSize}
                      className="betItem m-0">
                      <BetBox
                        providerId={matchId}
                        outcomeId={outcome.id}
                        marketStringId={market.stringId}
                        sport={props.sportType}
                        path={props.path}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Container>
        );
      case EnumMarketName.MULTISCORES_551:
        return (
          <Container>
            <Row className="align-items-start">
              {path === '/match-detail' && (
                <Col xs={6} className="bet_name">
                  <div>&nbsp;</div>
                  {OutcomeName(market.id, market.stringId, market.name)}
                </Col>
              )}
              <Col className={`${path === '/match-detail' ? 'ps-3' : 'pl-0'}`} xs={path === '/match-detail' ? 6 : 10}>
                <Row className="gy-2 gx-1 justify-content-end">
                  {sortedOutcomes?.map((outcome, index) => (
                    <Col
                      key={market.id + ' ' + (outcome.id || matchId + '' + index)}
                      xs={oddsSize}
                      className="d-flex flex-column"
                      style={{ minHeight: '3.8rem', justifyContent: 'flex-end' }}>
                      <BetBox
                        providerId={matchId}
                        outcomeId={outcome.id}
                        marketStringId={market.stringId}
                        sport={props.sportType}
                        path={props.path}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Container>
        );
      default:
        return (
          <Container>
            <Row className="align-items-start">
              {path === '/match-detail' && (
                <Col xs={6} className="bet_name">
                  <div>&nbsp;</div>
                  {OutcomeName(market.id, market.stringId, market.name)}
                </Col>
              )}
              <Col className={`${path === '/match-detail' ? 'ps-3' : 'pl-0'}`} xs={path === '/match-detail' ? 6 : 10}>
                <Row className="bets gy-2 gx-1 justify-content-end">
                  {sortedOutcomes?.map((outcome, index) => (
                    <Col
                      key={market.id + ' ' + (outcome.id || matchId + '' + index)}
                      xs={oddsSize}
                      className="d-flex flex-column">
                      <BetBox
                        providerId={matchId}
                        outcomeId={outcome.id}
                        marketStringId={market.stringId}
                        sport={props.sportType}
                        path={props.path}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Container>
        );
    }
  }

  return <Row className="betname_bets_wrapper">{market && getMarkets()}</Row>;
}

export default BetTitleAndBox;
